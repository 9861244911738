import { CMS_ROUTE } from "./constants"
import Axios from 'axios';
import { IProject, IService } from "./interfaces";

export const getServices = async (): Promise<IService[]> => {
    const api = `${CMS_ROUTE}/main/api/collections/get/services`;
    const services: Array<IService> = (await Axios.get(api)).data.entries;
    return services;
}

export const getPortfolio = async (limit?: number): Promise<IProject[]> => {
    const api = `${CMS_ROUTE}/main/api/collections/get/projects`;
    const projects: Array<IProject> = (await Axios.get(api, {
        params: {
            "sort[_created]": -1,
            "limit": limit,
            "filter[published]": true
        }
    })).data.entries;
    return projects;
}

export const getImageRouteFromDrupalApi = async (url: string): Promise<string> => {
    // Drupal no devuelve las rutas a los campos de tipo imagen por considerarse "Relationship".
    // Use esta función para obtener la ruta a una imagen a través del enlace que drupal devuelve en relationships.field_image.links.related.href.
    const data = await Axios.get(url);
    return data.data.data.attributes.uri.url;
}

export const getProjectById = async (id: string): Promise<IProject> => {
    const api = `${CMS_ROUTE}/main/api/collections/get/projects?filter[_id]=${id}`;
    const project = await Axios.get(api);
    return project.data.entries[0];
}